// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/kAYnYYdib0aQPNKZpgJT6J/%5BPreview%5D-Minimal-Web.v5.0.0?type=design&node-id=0%3A1&t=Al4jScQq97Aly0Mn-1',
  // AUTH
  auth: {
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
      newPassword: `${ROOTS.AUTH}/firebase/new-password`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    banking: `${ROOTS.DASHBOARD}/banking`,
    transactions: `${ROOTS.DASHBOARD}/transactions`,
    grid: `${ROOTS.DASHBOARD}/grid`,
    analytics: `${ROOTS.DASHBOARD}/analytics`,
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
    },
    bankAccount: {
      root: `${ROOTS.DASHBOARD}/bank-account`,
      new: `${ROOTS.DASHBOARD}/bank-account/new`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/bank-account/${id}/edit`,
    },
  },
};
