import React, { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// GENERAL
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewTransactionsPage = lazy(() => import('src/pages/dashboard/transactions'));
const OverviewGridPage = lazy(() => import('src/pages/dashboard/grid'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewAccountsPage = lazy(() => import('src/pages/dashboard/accounts'));
// USER
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
// BANK ACCOUNT
const BankAccountListPage = lazy(() => import('src/pages/dashboard/bank-account/list'));
const BankAccountCreatePage = lazy(() => import('src/pages/dashboard/bank-account/new'));
const BankAccountEditPage = lazy(() => import('src/pages/dashboard/bank-account/edit'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <OverviewBankingPage />, index: true },
      { path: 'transactions', element: <OverviewTransactionsPage /> },
      { path: 'grid', element: <OverviewGridPage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'accounts', element: <OverviewAccountsPage /> },
      {
        path: 'user',
        children: [{ element: <UserAccountPage />, index: true }],
      },
      {
        path: 'bank-account',
        children: [
          { element: <BankAccountListPage />, index: true },
          { path: 'new', element: <BankAccountCreatePage /> },
          { path: ':id/edit', element: <BankAccountEditPage /> },
        ],
      },
    ],
  },
];
